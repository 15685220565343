import { styled } from '@mui/system';  
import { AppBar, Toolbar } from '@mui/material';  
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';  
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';  
  
// Custom AppBar with styles  
export const CustomAppBar = styled(AppBar)(({ theme }) => ({  
  backgroundColor: '#ffd5d5', // Customize the background color  
  boxShadow: 'none', // Remove the box shadow  
  zIndex: 1000,  
  transition: 'top 0.3s', // Add transition for smooth hide/show  
}));  
  
// Custom Toolbar with styles  
export const CustomToolbar = styled(Toolbar)({  
  margin: '5',  
  justifyContent: 'space-between', // Space out the items  
});  
  
// Custom Logo with styles  
export const Logo = styled('img')(({ theme }) => ({  
  height: '120px',  
  marginRight: '20px',  
  [theme.breakpoints.down('sm')]: {  
    width: '90px', // Adjust size for small devices  
    height: '70px',  
  },  
}));  
  
export const CustomPhotoCameraOutlinedIcon = styled(PhotoCameraOutlinedIcon)`  
  width: 60px;  
  height: 60px;  
  
  @media (max-width: 768px) {  
    width: 40px;  
    height: 40px;  
  }  
`;  
  
export const CustomPhotoLibraryOutlinedIcon = styled(PhotoLibraryOutlinedIcon)`  
  width: 60px;  
  height: 60px;  
  
  @media (max-width: 768px) {  
    width: 40px;  
    height: 40px;  
  }  
`;  
