import styled from 'styled-components'; 
import { SocialIcon } from 'react-social-icons'
  
export const CustomSocialIcon = styled(SocialIcon)`
  height: 50;
  width: 50;
  margin: "5px";
    &:hover {  
    transform: scale(1.1) 
  }  
`;


export const FooterContainer = styled.footer`  
  background-color: #ffd5d5;  
  color: #fff;  
  padding: 50px 0;  
  text-align: center;  
`;  
  
export const Container = styled.div`  
  max-width: 1200px;  
  margin: 0 auto;  
  padding: 0 20px;  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
  justify-content: space-between;  
`;  
  
export const FooterText = styled.p`  
  margin: 2px;  
  font-size: 14px;  
  font-family: cursive;
`;  
  
export const FooterLink = styled.a`  
  color: #fff;  
  margin: 5px 10px;  
  padding 0 2px;
  text-decoration: none;  
  font-size: 14px;  
  font-family: 'Great Vibes';
  text-align: center;  
  
  &:hover {  
    text-decoration: underline;  
  }  
`;  