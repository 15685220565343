import { useNavigate } from 'react-router-dom';  
import { useAuth } from '../AuthContext/AuthContext';  
import { Button, TextField, Box } from '@mui/material';  
import React, { useState } from 'react';  
  
const LoginPage: React.FC = () => {  
  const [email, setEmail] = useState('');  
  const [password, setPassword] = useState('');  
  const { login } = useAuth();  
  const navigate = useNavigate();  
  
  const handleLogin = () => {  
    // Perform your authentication logic here, e.g., call an API  
    const user = { email }; // Replace this with actual user data  
    login(user);  
    navigate('/admin'); // Redirect to the admin page after login  
  };  
  
  return (  
    <Box>  
      <TextField  
        label="Email"  
        value={email}  
        onChange={(e) => setEmail(e.target.value)}  
        fullWidth  
        margin="normal"  
      />  
      <TextField  
        label="Password"  
        type="password"  
        value={password}  
        onChange={(e) => setPassword(e.target.value)}  
        fullWidth  
        margin="normal"  
      />  
      <Button onClick={handleLogin} variant="contained" color="primary">  
        Log In  
      </Button>  
    </Box>  
  );  
};  
  
export default LoginPage;  
