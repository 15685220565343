import React, { createContext, useContext, useState, ReactNode } from 'react';  
  
interface AuthContextType {  
  currentUser: any;  
  login: (user: any) => void;  
  logout: () => void;  
}  
  
const AuthContext = createContext<AuthContextType | undefined>(undefined);  
  
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {  
  const [currentUser, setCurrentUser] = useState<any>(() => {  
    // Load user from local storage if available  
    const user = localStorage.getItem('currentUser');  
    return user ? JSON.parse(user) : null;  
  });  
  
  const login = (user: any) => {  
    setCurrentUser(user);  
    localStorage.setItem('currentUser', JSON.stringify(user));  
  };  
  
  const logout = () => {  
    setCurrentUser(null);  
    localStorage.removeItem('currentUser');  
  };  
  
  return (  
    <AuthContext.Provider value={{ currentUser, login, logout }}>  
      {children}  
    </AuthContext.Provider>  
  );  
};  
  
export const useAuth = () => {  
  const context = useContext(AuthContext);  
  if (!context) {  
    throw new Error('useAuth must be used within an AuthProvider');  
  }  
  return context;  
};  
